<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">
    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        Últimos empleos
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        ¿Qué tal te ha ido en otros trabajos? Para conocer mejor tu perfil laboral, en este paso puedes agregar información sobre los empleos en los que estuviste anteriormente.
      </div>
    </div>

    <ValidationObserver ref="formstepPiK" tag="div"  class="ppi_contentZone spacerRowTop48" v-slot="{ handleSubmit, valid, passed }">
      <q-form 
        ref="myFormPik"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPikReset"
        class="formDetailZone "
      >
        <!-- Elemento x cada empleo -->
        <div
          v-for="(empleo, indexEmpleo) in frmData"
          :id="'empleoItem_' + indexEmpleo + '_frmdata'"
          :key="'empleoItem_' + indexEmpleo + '_frmdata'"
          class="dataSection_container dataSection_separadorx"
          style="border-bottom: 1px solid rgb(220, 219, 225);"
        >
            <!-- 
              :class="{
                'dataSection_separadorx': (indexEmpleo === frmData.length-1) ? false: true 
              }"
            -->
            <div 
              :id="'empleoItemContent_' + indexEmpleo + '_frmdata'" class="datasectionItem"
              :style="(is_Mobile === true || $q.screen.lt.md) ? '' : 'max-width:750px;'"
            >
              <!-- LINEA 1  -->
              <div class="row q-gutter-md" style="margin: 0px;">
                <!-- Campo nombre de la empresa -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'margin-left: 0px;max-width: 48% !important;'"
                >

                  <ValidationProvider 
                    tag="div"
                    :ref="'fldx_empresa_' + indexEmpleo " 
                    :name="'nombre_empresa_' + indexEmpleo" 
                    :rules=" { required: true, emojiFree:true, min:4, max:45 }" 
                    :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          NOMBRE DE LA EMPRESA 
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        filled
                        name="calle"
                        v-model="empleo.nombre_empresa"
                        maxlength="45"
                        hint=""
                        :dense="true"
                        :label="(empleo.nombre_empresa==='' || empleo.nombre_empresa===null)?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  
                         errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' : 
                          errors[0].replace('nombre_empresa_' + indexEmpleo,'Nombre de empresa').replace('El campo','').replace('es obligatorio','es un valor obligatorio') : ''"
                      >
                        <template v-slot:append>
                          <q-icon v-if="empleo.nombre_empresa !== ''" class="cursor-pointer" name="clear" @click.stop="empleo.nombre_empresa = ''"/>
                        </template>
                      </q-input>
                  </ValidationProvider>

                </div>

                <!-- Campo puesto -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width: 48% !important;'"
                >
                  <ValidationProvider 
                    tag="div"
                    :ref="'fldx_puesto_' + indexEmpleo " 
                    :name="'puesto_' + indexEmpleo" 
                    :rules=" { required: true, emojiFree:true, min:4, max:45 }" 
                    :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          ¿QUÉ PUESTO OCUPASTE?
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        filled
                        name="calle"
                        v-model="empleo.puesto"
                        maxlength="45"
                        hint=""
                        :dense="true"
                        :label="(empleo.puesto_==='' || empleo.puesto_===null)?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  
                          errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' : 
                            errors[0].replace('puesto_' + indexEmpleo,'Puesto').replace('El campo','').replace('es obligatorio','es un valor obligatorio') : ''"
                      >
                        <template v-slot:append>
                          <q-icon v-if="empleo.puesto !== ''" class="cursor-pointer" name="clear" @click.stop="empleo.puesto = ''"/>
                        </template>
                      </q-input>
                  </ValidationProvider>
                </div>

              </div>

              <!-- LINEA 2  -->
              <!-- FECHAS de TRABAJO -->
              <div class="row" style="margin: 0px;margin-top: 16px;">
                <div class="col-12 col-sm-12 col-md-12" :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : ''">
                  <div class="fieldLabel labelFx_Field text-left noselect">
                    ¿CUÁNTO TIEMPO TRABAJASTE AHÍ?
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12 row q-gutter-md" style="margin: 0px;">
                  <!-- Campos de Fecha de inicio -->
                  <div class="col-12 col-sm-12 col-md-6"
                    :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'margin-left: 0px;max-width: 48% !important;'"
                  >

                    <ValidationProvider
                      :rules=" { required:true, cannaQuest_DateIsValid:true } " 
                      :immediate="true" 
                      :ref="'fldx_fechainicio_' + indexEmpleo " 
                      :name="'fecha_inicio_' + indexEmpleo" 
                      v-slot="{ errors, invalid, validated  }"
                      tag="div" class="row justify-between"
                    >
                      <input type="hidden" name="fecha_inicio"  v-model="empleo.fechaInicio.fecha">
                      <div class="col-12" style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">Fecha de inicio</div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <!-- año list_dtRefMeses -->
                      <div class="col-6 row" style="margin-top: 8px !important;max-width: 48%;">

                        <div class="col-12" style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">Año</div>
                          <div class="requiredLabel text-right" >&nbsp;</div>
                        </div>
                        <div class="col-12">
                          <q-select
                           :name="'fechainicio_year' + indexEmpleo"
                            filled
                            :dense="true"
                            v-model="empleo.fechaInicio.year"
                            :label="(empleo.fechaInicio.year ==='' || empleo.fechaInicio.year === null) ? 'Año' : ''"
                            :options="catdata_years"
                            option-value="value"
                            option-label="label"
                            emit-value
                            map-options
                            :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                            :error="invalid && validated"
                            @input="pij_updateFechaInicio(indexEmpleo)"
                            @blur="pij_doCheckFechasInicio(indexEmpleo)"
                          >
                            <template v-slot:append>
                              <q-icon name="clear" class="cursor-pointer" v-if="empleo.fechaInicio.year !== null" @click.stop="empleo.fechaInicio.year = null"/>
                            </template>

                            <template v-slot:option="scope">
                              <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                                <q-item-section>
                                  <q-item-label>
                                    {{scope.opt.label}}
                                  </q-item-label>
                                </q-item-section>
                              </q-item>
                            </template>

                          </q-select>
                        </div>
                      </div>

                      <!-- mes -->
                      <div class="col-6 row canna_forma_campo_container" style="margin-top: 8px !important; max-width: 48%;">
                        <div class="col-12" style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">Mes</div>
                          <div class="requiredLabel text-right" >&nbsp;</div>
                        </div>
                        <div class="col-12">

                          <q-select
                            :name="'fechainicio_month' + indexEmpleo"
                            filled
                            :dense="true"
                            v-model="empleo.fechaInicio.month"
                            :label="(empleo.fechaInicio.month ==='' || empleo.fechaInicio.month === null) ? 'Mes' : ''"
                            :options="list_refMesesSelect"
                            option-value="value"
                            option-label="label"
                            emit-value
                            map-options
                            :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                            :error="invalid && validated"
                            @input="pij_updateFechaInicio(indexEmpleo)"
                            @blur="pij_doCheckFechasInicio(indexEmpleo)"
                          >
                          <!--  :error-message="(errors[0] !==undefined) ? errors[0].replace('tipolicencia','Tipo de licencia').replace('El campo','') : '' " -->
                            <template v-slot:append>
                              <q-icon name="clear" class="cursor-pointer" v-if="empleo.fechaInicio.month !== null" @click.stop="empleo.fechaInicio.month = null"/>
                            </template>

                            <template v-slot:option="scope">
                              <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                                <q-item-section>
                                  <q-item-label>
                                    {{scope.opt.label}}
                                  </q-item-label>
                                </q-item-section>
                              </q-item>
                            </template>

                          </q-select>

                        </div>
                      </div>

                      <template v-if="(invalid && validated && errors[0]!==undefined)">
                        <div class="col-12" style="margin-top: 0px;">
                          <div class="q-field--error q-field__bottom" 
                            style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center; font-size: 11px;"
                          >
                          {{ errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' : errors[0].replace('fecha_inicio_' + indexEmpleo , 'Fecha de inicio').replace('El campo','') }}
                          </div>
                        </div>
                      </template>

                    </ValidationProvider>

                  </div>
                  <!-- Campos Fecha de fin  -->
                  <div class="col-sm-12 col-md-6"
                    :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width: 48% !important;'"
                  >

                    <ValidationProvider
                      :rules=" { required:true, oneOf: [0,1]} " 
                      :immediate="false" 
                      :ref="'fldx_tipoFin_' + indexEmpleo " 
                      :name="'fecha_fintipo_' + indexEmpleo" 
                      v-slot="{ errors, invalid, validated  }"
                      tag="div" class="row"
                    >
                      <div class="col-12" style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect"
                          :class="{'fieldError':invalid && validated }"
                        >Fecha de fin</div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <input type="hidden" :name="'fecha_fintipo_' + indexEmpleo"  v-model="empleo.actualmente_trabaja_ahi">

                      <div class="col-12 row" style="margin-left: -10px;">

                        <q-option-group
                          class="canna_optionGroup_A"
                          :name="'tipofinlaboral_'+ indexEmpleo"
                          type="radio"
                          keep-color
                          :options="[
                            { label: 'Actualmente trabajo aquí', value: 1, color:(invalid && validated && errors[0] !==undefined || empleo.actualmente_trabaja_ahi===null ) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                            { label: 'He concluido mi relación laboral', value:0, color:(invalid && validated && errors[0] !==undefined || empleo.actualmente_trabaja_ahi===null) ? 'cannaRadioError' : 'canna_ColorRadio' }
                          ]"
                          v-model="empleo.actualmente_trabaja_ahi"
                        />
                      </div>

                      <template v-if="(invalid && validated && errors[0] !==undefined)">
                        <div class="q-field--error q-field__bottom" 
                          style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center; font-size: 11px;"
                        >
                        {{ errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' : errors[0].replace('fecha_fintipo_' + indexEmpleo,'Fecha de fin').replace('El campo','') }}
                        </div>
                      </template>
                    
                    </ValidationProvider>

                    <template v-if="(empleo.actualmente_trabaja_ahi===0)"> <!-- Si la fecha fin de estudios es de tipo  753 -->
                      <ValidationProvider
                        :rules=" {
                          required: (empleo.actualmente_trabaja_ahi===0) ? true : false , 
                          cannaQuest_DateIsValid:true,
                          cannaQuest_laboralFechaFinVsInicio:{
                            tipoFinUse:empleo.actualmente_trabaja_ahi, 
                            fechaIni:'@fecha_inicio_' + indexEmpleo
                          }
                        }"
                        :immediate="true"
                        :ref="'fldx_fechafin_' + indexEmpleo " 
                        :name="'fecha_final_' + indexEmpleo" 
                        v-slot="{ errors, invalid, validated  }" 
                        tag="div" 
                        class="row justify-between"
                        :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'margin-left: 10px;'" 
                      >
                        <input type="hidden" name="fecha_final"  v-model="empleo.fechaFin.fecha">
                        <!-- año list_dtRefMeses -->
                        <div class="col-6 row" style="margin-top: 8px !important;max-width: 48%;">
                          <div class="col-12" style="display: flex;justify-content: space-between;">
                            <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">Año</div>
                            <div class="requiredLabel text-right" >&nbsp;</div>
                          </div>
                          <div class="col-12">
                            <q-select
                              name="fechafin_year"
                              filled
                              :dense="true"
                              v-model="empleo.fechaFin.year"
                              :label="(empleo.fechaFin.year ==='' || empleo.fechaFin.year === null) ? 'Año' : ''"
                              :options="catdata_years"
                              option-value="value"
                              option-label="label"
                              emit-value
                              map-options
                              :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                              :error="invalid && validated"
                              @input="pij_updateFechaFin(indexEmpleo)"
                              @blur="pij_doCheckFechasFin(indexEmpleo)"
                            >
                              <template v-slot:append>
                                <q-icon name="clear" class="cursor-pointer" v-if="empleo.fechaFin.year !== null" @click.stop="empleo.fechaFin.year = null"/>
                              </template>

                              <template v-slot:option="scope">
                                <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                                  <q-item-section>
                                    <q-item-label>
                                      {{scope.opt.label}}
                                    </q-item-label>
                                  </q-item-section>
                                </q-item>
                              </template>

                            </q-select>
                          </div>
                        </div>

                        <!-- mes -->
                        <div class="col-6 row canna_forma_campo_container" style="margin-top: 8px !important; max-width: 48%;">
                          <div class="col-12" style="display: flex;justify-content: space-between;">
                            <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">Mes</div>
                            <div class="requiredLabel text-right" >&nbsp;</div>
                          </div>
                          <div class="col-12">

                            <q-select
                              name="fechafin_month"
                              filled
                              :dense="true"
                              v-model="empleo.fechaFin.month"
                              :label="(empleo.fechaFin.month ==='' || empleo.fechaFin.month === null) ? 'Mes' : ''"
                              :options="list_refMesesSelect"
                              option-value="value"
                              option-label="label"
                              emit-value
                              map-options
                              :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                              :error="invalid && validated"
                              @input="pij_updateFechaFin(indexEmpleo)"
                              @blur="pij_doCheckFechasFin(indexEmpleo)"
                            >
                              <template v-slot:append>
                                <q-icon name="clear" class="cursor-pointer" v-if="empleo.fechaFin.month !== null" @click.stop="empleo.fechaFin.month = null"/>
                              </template>

                              <template v-slot:option="scope">
                                <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                                  <q-item-section>
                                    <q-item-label>
                                      {{scope.opt.label}}
                                    </q-item-label>
                                  </q-item-section>
                                </q-item>
                              </template>

                            </q-select>

                          </div>
                        </div>

                        <template v-if="(invalid && validated && errors[0] !==undefined)">
                          <div class="col-12" style="margin-top: 0px;">
                            <div class="q-field--error q-field__bottom" 
                              style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center; font-size: 11px;"
                            >
                            {{ 
                              errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' :
                                (errors[0].indexOf('El año y mes de la fecha de fin')>=0) ? 
                                  errors[0].replace('fecha_final_' + indexEmpleo,'').replace('El campo','') : 
                                    errors[0].replace('fecha_final_' + indexEmpleo, 'Fecha de fin de trabajo').replace('El campo','') 
                            }}
                            </div>
                          </div>
                        </template>

                      </ValidationProvider>

                    </template>

                  </div>

                </div>

              </div>

              <!-- LINEA 3  -->
              <div class="row q-gutter-md" style="margin: 0px;">
                <!-- Campo Sueldo mensual -->
                <div class="col-12 col-sm-12 col-md-6"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'margin-left: 0px;max-width: 48% !important;'"
                >
                
                  <ValidationProvider 
                    :ref="'fldx_sueldomensual_' + (indexEmpleo) " 
                    :name="'sueldomensual_' + (indexEmpleo)" 
                    :immediate="true"
                    :rules="{ required: true, cannaSueldosLaboral:true }" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                    <!-- <input type="hidden" name="SueldoMensual" v-model="formData.generales.sueldoMensual"> -->
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated}">SUELDO MENSUAL</div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>

                    <q-field
                      filled
                      :name="'sueldomensual_' + (indexEmpleo)" 
                      :dense="true"
                      hint="Ingresa el costo en pesos"
                      v-model="empleo.sueldo_mensual"
                      @blur="jps_onBlurConceptoMoney('mensual', $event, empleo, indexEmpleo)"
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ? 
                        (empleo.sueldo_mensual === '$ 0.00') ? 'Este dato es obligatorio' : 
                          errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' :
                            errors[0].replace('El campo','').replace('sueldomensual_' + (indexEmpleo),'Sueldo mensual') : '' "
                    >
                      <template v-slot:prepend>
                      <q-icon
                        v-if="empleo.sueldo_mensual !== '$ 0.00'" 
                        class="cursor-pointer" name="clear"
                        @click.stop="empleo.sueldo_mensual = '$ 0.00'"
                      />
                      </template>
                      <template v-slot:control="{ id, floatingLabel, value, emitValue}">
                        <money 
                          :id="id" 
                          class="q-field__input text-right" 
                          :value="value" 
                          @input="emitValue"
                          v-bind="moneyComp_Settings"
                          v-show="floatingLabel"
                        />
                      </template>
                    </q-field>
                  </ValidationProvider>

                </div>
                <!-- Campo Motivo de separacion -->
                <div class="col-12 col-sm-12 col-md-6" style="position: relative;"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : 'max-width: 48% !important;'"
                >
                  <template v-if="(empleo.actualmente_trabaja_ahi===1  || empleo.actualmente_trabaja_ahi===null) ? true : false">
                    <div id="mask_motivosepara_1_blocker" style="width: 100%;height: 100%;position: absolute;background-color: white;z-index: 1;opacity: 0.01; cursor:not-allowed;"></div>
                  </template>

                  <ValidationProvider 
                    tag="div"
                    :ref="'fldx_motivosepara_' + indexEmpleo " 
                    :name="'motivosepara_' + indexEmpleo" 
                    :rules=" { required: (empleo.actualmente_trabaja_ahi===0) ?  true : false , oneOf: z_allowmotivoSepara }" 
                    :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect"
                          :class="{'fieldError':invalid && validated }"
                        >MOTIVO DE SEPARACIÓN</div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-select
                        :name="'motivosepara_' + indexEmpleo" 
                        filled
                        :dense="true"
                        v-model="empleo.catalogo_id"
                        :label="(empleo.catalogo_id ==='' || empleo.catalogo_id === null) ? 'Elige una opción' : ''"
                        :options="cat_separacion.dataCatalog"
                        option-value="id"
                        option-label="nombre"
                        emit-value
                        map-options
                        :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "

                        :disable="(empleo.actualmente_trabaja_ahi===1 || empleo.actualmente_trabaja_ahi===null) ? true : false"
                        :disabled="(empleo.actualmente_trabaja_ahi===1 || empleo.actualmente_trabaja_ahi===null) ? true : false"
                        :readonly="(empleo.actualmente_trabaja_ahi===1 || empleo.actualmente_trabaja_ahi===null) ? true : false"

                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? 
                          errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' :
                            errors[0].replace('motivosepara_' + indexEmpleo,'Motivo de separación').replace('El campo','') : '' "
                      >
                        <template v-slot:append>
                          <q-icon v-if="empleo.catalogo_id !== null" class="cursor-pointer" name="clear" @click.stop="empleo.catalogo_id = null"/>
                        </template>
                        <template v-slot:option="scope">
                          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                            <q-item-section>
                              <q-item-label>
                                {{scope.opt.nombre}}
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                  </ValidationProvider>

                </div>

              </div>

              <!-- LINEA 4  -->
              <div class="row" style="margin: 0px; margin-top:24px;">

                <div class="col-12 col-sm-12 col-md-12" style="margin: 0px; margin-bottom:10px;"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : ''"
                >
                  <div class="fieldLabel labelFx_Field text-left noselect">
                    CONTACTO LABORAL
                  </div>
                </div>

                <div :id="'linea_4_' + indexEmpleo" class="col-12 col-sm-12 col-md-12 row q-gutter-sm" style="margin: 0px;">

                  <!-- NOMBRE de CONTACTO -->
                  <ValidationProvider 
                    tag="div"
                    class="col-12 col-sm-12 col-md-4"
                    :ref="'fldx_contacto_' + indexEmpleo " 
                    :name="'nombre_contacto_' + indexEmpleo" 
                    :rules=" { required: false, emojiFree:true, max:100 }" 
                    :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                    style="margin-left: 0px; position: relative;"
                  >
                      <template v-if="(empleo.cuenta_con_datos_contacto===1) ? true : false">
                        <div id="mask_datacontacto_1_blocker" style="width: 100%;height: 100%;position: absolute;background-color: white;z-index: 1;opacity: 0.01;cursor:not-allowed;"></div>
                      </template>

                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          NOMBRE DEL CONTACTO
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        filled
                        :name="'nombre_contacto_' + indexEmpleo" 
                        v-model="empleo.nombre_contacto"
                        maxlength="100"
                        hint=""
                        :dense="true"
                        :label="(empleo.nombre_contacto==='' || empleo.nombre_contacto===null)?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  
                          errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' :
                            errors[0].replace('nombre_contacto_' + indexEmpleo,'Nombre de contacto').replace('El campo','').replace('es obligatorio','es un valor obligatorio') : ''"
                      >
                        <template v-slot:append>
                          <q-icon v-if="empleo.nombre_contacto !== ''" class="cursor-pointer" name="clear" @click.stop="empleo.nombre_contacto = ''"/>
                        </template>
                      </q-input>
                  </ValidationProvider>

                  <!-- RELACION de CONTACTO -->
                  <ValidationProvider 
                    tag="div"
                    class="col-12 col-sm-12 col-md-4"
                    :ref="'fldx_relacioncontacto_' + indexEmpleo " 
                    :name="'relacion_contacto_' + indexEmpleo" 
                    :rules=" { required: false, emojiFree:true, max:100 }" 
                    :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                     style="position: relative;"
                    :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : ''"
                  >
                      <template v-if="(empleo.cuenta_con_datos_contacto===1) ? true : false">
                        <div id="mask_datacontacto_2_blocker" style="width: 100%;height: 100%;position: absolute;background-color: white;z-index: 1;opacity: 0.01; cursor:not-allowed;"></div>
                      </template>

                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                          RELACIÓN LABORAL
                        </div>
                        <div class="requiredLabel text-right" >&nbsp;</div>
                      </div>
                      <q-input
                        filled
                        :name="'relacion_contacto_' + indexEmpleo" 
                        v-model="empleo.relacion_laboral"
                        maxlength="100"
                        hint=""
                        :dense="true"
                        :label="(empleo.relacion_laboral==='' || empleo.relacion_laboral===null)?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  
                          errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' :
                            errors[0].replace('relacion_contacto_' + indexEmpleo,'Relación laboral').replace('El campo','').replace('es obligatorio','es un valor obligatorio') : ''"
                      >
                        <template v-slot:append>
                          <q-icon v-if="empleo.relacion_laboral !== ''" class="cursor-pointer" name="clear" @click.stop="empleo.relacion_laboral = ''"/>
                        </template>
                      </q-input>
                  </ValidationProvider>

                  <!-- TELEFONO de CONTACTO -->
                  <ValidationProvider
                    tag="div"
                    class="col-12 col-sm-12 col-md"
                    style="position: relative;"
                    :ref="'fldx_telcontacto_' + indexEmpleo " 
                    :name="'tel_contacto_' + indexEmpleo"
                    :rules=" { 
                      required:false, 
                      cannaPhones:true,
                      emojiFree:true,
                      min:10,
                      max:10
                    } " 
                    :immediate="true"
                    v-slot="{ errors, invalid, validated  }"
                  >
                    <template v-if="(empleo.cuenta_con_datos_contacto===1) ? true : false">
                      <div id="mask_datacontacto_3_blocker" style="width: 100%;height: 100%;position: absolute;background-color: white;z-index: 1;opacity: 0.01;cursor:not-allowed;"></div>
                    </template>

                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated }">
                        TELÉFONO DE CONTACTO
                      </div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>
                    <q-input
                      filled
                      :name="'tel_contacto_' + indexEmpleo"
                      v-model="empleo.telefono_contacto"
                      hint=""
                      :dense="true"
                      :mask="'(##) #### ####'"
                      unmasked-value
                      :label="(empleo.telefono_contacto==='' ||  empleo.telefono_contacto === null)? 'Número a 10 dígitos':''"
                      @blur="pie_validateByPhone(1, empleo, indexEmpleo )"
                      :error="invalid && validated"
                    >
                      <template v-slot:append>
                        <q-icon v-if="empleo.telefono_contacto !== null && empleo.telefono_contacto !== '' " class="cursor-pointer" 
                          name="clear" @click.stop="empleo.telefono_contacto = null"
                        />
                      </template>
                    </q-input>
                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="" style="">
                        <div class="q-field--error q-field__bottom" 
                          style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 0px; display: flex; align-items: center;"
                        >
                        {{ 
                          errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' :
                            (errors[0].indexOf('mismo que tu número celular')>=0) ? 
                              errors[0].replace('tel_contacto_' + indexEmpleo, 'teléfono de contacto').replace('El campo','') : 
                                (errors[0].indexOf('mismo que el número de recados')>=0) ? 
                                  errors[0].replace('tel_contacto_' + indexEmpleo, 'teléfono de contacto').replace('El campo','') : 
                                    (errors[0].indexOf('El número no existe')>=0) ?  errors[0].replace('tel_contacto_' + indexEmpleo, 'teléfono de contacto').replace('El campo','')  : 
                                      empleo.telefono_contactoerrores
                        }}
                        </div>
                      </div>
                    </template>
                  </ValidationProvider>

                  <ValidationProvider 
                    tag="div"
                    class="col-12 col-sm-12 col-md-12"
                    :ref="'fldx_tengocontacto_' + indexEmpleo " 
                    :name="'tengocontacto_' + indexEmpleo"
                    :rules=" { required: true, oneOf: [0,1] }" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                    :style="'margin-left: 0px;'"
                  >
                    <div style="" :class="(invalid && validated) ? 'novalidxx' : 'validxx' ">
                      <div>
                        <q-checkbox 
                          name="tengocontacto"
                          v-model="empleo.cuenta_con_datos_contacto"
                          :size="'sm'"
                          :true-value="1"
                          :false-value="0"
                          :val="1"
                          :label="'No cuento con los datos de algún contacto laboral de este empleo'"
                          color="cannaMainColor"
                          @input="(val, evt) => { fldx_checkDataContacto( val, evt, empleo, indexEmpleo ) }"
                        />
                      </div>
                    </div>

                    <div style="color:#EB5757; font-size: 12px;" v-if="(errors[0] !== undefined)">
                      Para poder continuar debes aceptar el manifiesto de decir verdad
                    </div>
                  </ValidationProvider>

                </div>

              </div>

              <!-- LINEA 5  -->
              <div class="row" style="margin: 0px; margin-top:24px;">
                <div class="col-12 col-sm-12 col-md-12" style="margin: 0px; margin-bottom:10px;"
                  :style="(is_Mobile === true || $q.screen.lt.md) ? 'margin-left: 0px;' : ''"
                >
                  <div class="fieldLabel labelFx_Field text-left noselect">
                    Comentarios adicionales
                  </div>
                </div>
                <div :id="'linea_5_' + indexEmpleo" class="col-12 col-sm-12 col-md-12 row" style="margin: 0px;">


                  <ValidationProvider 
                    tag="div"
                    class="col-12 col-sm-12 col-md-12 canna_forma_campo_txtareaX"
                    :ref="'fldx_comentario_' + indexEmpleo " 
                    :name="'comentario_' + indexEmpleo" 
                    :rules=" { required: false, emojiFree:true, max:3000 }" 
                    :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                    style="margin-left: 0px;"
                  >
                      <q-input
                        filled
                        type="textarea"
                        class="canna_forma_campo_txtarea canna_forma_campo_txtareaX"
                        :autogrow="false"
                        :name="'comentario_' + indexEmpleo" 
                        v-model="empleo.comentarios"
                        maxlength="3000"
                        hint=""
                        :dense="true"
                        :label="(empleo.comentarios==='' || empleo.comentarios===null)?'Si lo necesitas, en este espacio puedes contarnos algo sobre tu experiencia laboral en este trabajo':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  
                          errors[0].indexOf('obligatorio')>=0 ? 'Este dato es obligatorio' :
                            errors[0].replace('nombre_contacto_' + indexEmpleo,'Nombre de contacto').replace('El campo','').replace('es obligatorio','es un valor obligatorio') : ''"
                      />
                  </ValidationProvider>

                </div>

              </div>

              <!-- Eliminar este empleo -->
              <template v-if="frmData.length > 1">
                <div class="row" style="margin: 0px; margin-top:24px;">
                  <div class="col-12 col-sm-12 col-md-12 experienciaLAboralAcciones" style="margin: 0px 0px 10px;justify-content: center !important;display: flex;">
                    <div class="action_item empleoRemove noselect" style="" @click.stop="pikb_removeEmpleo(indexEmpleo)">Eliminar este empleo</div>
                  </div>
                </div>
              </template>

            </div>

        </div>

        <template v-if="frmData.length<3">
          <div class="dataSection_container" style="margin:48px 0px;">
            <div class="experienciaLAboralAcciones" style="display: flex;justify-content: flex-start;">
              <div class="action_item empleoAdd noselect" style="" @click.stop="pikb_AddEmpleo">Agregar otro empleo</div>
            </div>
          </div>
        </template>

        <!--  Manifesto de verdad -->
        <div class="dataSection_container spacerRowTop48">
            <ValidationProvider 
              tag="div"
              class="datasectionItem manifesto_laboral"
              ref="fldx_manifesto" name="manifesto" :rules=" { required: true, oneOf: [true] }" :immediate="false" 
              v-slot="{ errors, invalid, validated  }"
            >
              <div style="background: #FDF5DD; border-radius: 8px; padding:16px 24px;"
                :style="(invalid && validated) ? 'border: 1px solid #EB5757;' : '' "
              >
                <div>
                  <q-checkbox 
                    name="manifesto"
                    v-model="manifiesto_decir_verdad"
                    :label="'Manifiesto de decir verdad'"
                    color="cannaMainColor"
                  />
                </div>
                <div style="padding-left: 10px;">
                  La información que proporciones relacionada a tus empleos anteriores debe ser comprobable.
                </div>
              </div>

              <div style="color:#EB5757; font-size: 12px;" v-if="(errors[0] !== undefined)">
                Para poder continuar debes aceptar el manifiesto de decir verdad
              </div>
            </ValidationProvider>
        </div>

        <!-- Zona de botones -->
        <div class="ppi_pz_footer">
          <div class="rowButtons">

            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :class="((passed || valid) )? 'xxnovalid' : 'xxisvalid'"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>
    </ValidationObserver>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from './../../../../boot//axios' // APP code 
import logOut_Mixin from './../../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import { helperCatsFechas_Mixin } from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

import numeral from 'numeral'



const tplpikb_Data = {
  id: null,
  nombre_empresa: '',  // min y max = 			max 45
  puesto: '', // min y max = 			max 45
  fechaInicio:{ fecha:'' , year: null, month: null },
  actualmente_trabaja_ahi: null,  // 0 = NO  1= SI 
  fechaFin:{ fecha:'' , year: null, month: null },
  catalogo_id: null,  //MOtivo separacion
  cuenta_con_datos_contacto: 0,   //  --- 0 = NO  1= SI 
  nombre_contacto: '', // min y max = 			max 100
  relacion_laboral: '', // min y max = 			max 100
  telefono_contacto: '', //min y max = 			max 13 
  telefono_contactoerrores: '', //Para controlar validacion de errores en el telefono personalizados 
  comentarios: '', // campo TEXT 
  sueldo_mensual: '$ 0.00'
  // * (calculado) --- manifiesto_decir_verdad   (1)  
}

export default {
  name: 'psi_screen_empleos',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    helperCatsFechas_Mixin, 
    questionario_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      mynumeral: numeral, // Utilerias
      //-----------
      zloadingData: false,
      processingData: false,
      //-----------
      moneyComp_Settings: {
        decimal: '.', thousands: ',', prefix: '$ ', suffix: '', precision: 2, masked: true
      },
      //-----------
      catdata_years: [], //se almacen la lista de años para fecha inicial y final 
      manifiesto_decir_verdad: false,
      z_allowmotivoSepara: [],
      empleoRecords:[], //Los registros previamente guardados en este perfil 
      frmDataOriginal: JSON.parse(JSON.stringify( [tplpikb_Data] )),
      frmData: JSON.parse(JSON.stringify( [tplpikb_Data] ))
    }
  },
  computed: {
    cat_separacion (){ // catalogo id = 80 Motivo separación
      return this.getHelperCat(80) // (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(80) : this.getHelperCat(80)
    },

    mainErrorsZZ () { 
      if (this.$refs.formstepPiK) {
        return this.$refs.formstepPiK.errors
      } else { 
        return (-1)
      }
    },
    mainErrorsForm (){ 
       if (this.zinitScreen) {
         if (this.$refs.formstepPiK) {
           return this.$refs.formstepPiK
         } else { 
           return (-1)
         }
       } else { 
         return 0
       }
    },
    mainErrors () {
      var countx = 0
      if (this.zinitScreen) {
        try {
          if (this.$refs.formstepPiK) {
            if (this.$refs.formstepPiK.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiK.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiK.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_empleos --   test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/aptitudes-b'
      this.$cannaDebug('-- psiPage_empleos --   do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    onStepFormPikReset (){ 

    },
    pij_createYearDataList(){ 
      var myDataYears = []
      var fechaz = 1970
      var fechax = new Date()
      var yearinitial = fechax.getUTCFullYear()
      for (var i=fechaz;i<=(yearinitial);i++){  //for (var i=1946;i<=(yearinitial+4);i++){
        myDataYears.push({ label:'' + (i) , value: (i)})
      }
      myDataYears.reverse();
      this.catdata_years = myDataYears
    },
    pij_doCheckFechasInicio( indexItem ){ 
      var namex = 'fldx_fechainicio_' + indexItem
      if (this.$refs[namex][0]){
        this.$refs[namex][0].validate()
      }
    },
    pij_updateFechaInicio( indexItem ) {
      var namex = 'fldx_fechainicio_' + indexItem
      var fullfecha = ''
      if (this.frmData[indexItem].fechaInicio.year !== null ){
        fullfecha+= this.frmData[indexItem].fechaInicio.year
        if (this.frmData[indexItem].fechaInicio.month !== null ){
          fullfecha = this.$moment([this.frmData[indexItem].fechaInicio.year, (this.frmData[indexItem].fechaInicio.month - 1)]).startOf('month').format('YYYY-MM-DD')
        }
      }
      this.frmData[indexItem].fechaInicio.fecha = fullfecha
      this.$forceUpdate()
      if (this.$refs[namex][0]){
        this.$refs[namex][0].validate()
      }
    },
    pij_doCheckFechasFin( indexItem ){
      var namex = 'fldx_fechafin_' + indexItem
      if (this.$refs[namex][0]){
        this.$refs[namex][0].validate()
      }
    },
    pij_updateFechaFin( indexItem ) {
      var namex = 'fldx_fechafin_' + indexItem
      var fullfecha = ''
      if (this.frmData[indexItem].fechaFin.year !== null ){
        fullfecha+= this.frmData[indexItem].fechaFin.year
        if (this.frmData[indexItem].fechaFin.month !== null ){
          // fullfecha+= '-'
          // fullfecha+= (this.frmData.fechaFin.month<=9) ? '0' + this.frmData.fechaFin.month : this.frmData.fechaFin.month
          fullfecha = this.$moment([this.frmData[indexItem].fechaFin.year, (this.frmData[indexItem].fechaFin.month - 1)]).endOf('month').format('YYYY-MM-DD')
        }
      }
      this.frmData[indexItem].fechaFin.fecha = fullfecha
      this.$forceUpdate()
      if (this.$refs[namex][0]){
        this.$refs[namex][0].validate()
      }
    },
    jps_onBlurConceptoMoney: function (fieldTipo, event, item, index){
      var datanum = index
      this.$cannaDebug('-- psiPage_empleos -- jps onBlur Concepto Money -- ' + datanum)
      var valor = ''
      var newValor = 0
      //-----------
      if (fieldTipo === 'mensual') {
        valor = item.sueldo_mensual.replace('$ ', '')
        valor = valor.replace(/\,/g, '')
        var valorMensual = parseFloat(valor)
        item.sueldo_mensual = '$ ' + this.mynumeral(valorMensual).format('0,0.00')
      }
      //---------------
      this.$forceUpdate()
    },
    pie_validateByPhone: async function(tipoPh, empleoItem, indexItem){ 
      //tipoPh  1: que exista solamente  2: que sea FIJO 
      var test = 11
      var testerMode = tipoPh 
      var nameInput = 'tel_contacto_' + indexItem
      var nameCampox = 'fldx_telcontacto_' + indexItem
      var reqRes = { action: [] }, reqErr = { action: 0 }, myPhone = ''
      var myPhone = ('+52' + empleoItem.telefono_contacto).trim() 
        // (fieldX === 'telefono_recados') ? ('+52' + this.frmData.telefono_recados).trim() : ('+52' + this.frmData.telefono_casa).trim()
      if (myPhone.length!==13){
        return true 
      }
      var mpModel = this.getModelRequest('telefonoCheck')
      var myTelCfg = this.createAxiosConfig ('get', mpModel, { numero: myPhone }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'profilepersonales', reqRes.action, myTelCfg).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // no deberia haber errores nunca, solo es consulta 
        test = 44
        this.ex_handleErrorRequest(reqRes.action[0], reqErr.action)
        // this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepB, 'formstepB',  this.frmMainRefs.pasoB , 'pasoB')
        this.showLoadx(false, 1)
        // this.loading_Process = false
        this.$forceUpdate()
        return false 
      }
      var telData = reqRes.action[0].data.data
      if (telData.length<=0){
        // Mostrar error q no existe el telefono 
        var myObjectNoPhone = {}
        myObjectNoPhone[nameInput] = 'El número no existe o no corresponde a un número telefónico. Por favor, revisa e inténtalo de nuevo.'
        // if (fieldX === 'telefono_recados') { 
        // } else { 
        //   myObjectNoPhone = { tel_casa: 'El número no existe o no corresponde a un número telefónico. Por favor, revisa e inténtalo de nuevo.' }
        // }
        // if (this.$refs[nameCampox][0]){
        //   this.$refs[nameCampox][0].setErrors(myObjectNoPhone);
        // }
        if (this.$refs.formstepPiK){
          this.$refs.formstepPiK.setErrors(myObjectNoPhone)
        }
        this.$forceUpdate()
        return false
      }
      return true
    },
    pikb_resetEmpleoFields (indexItem ){ 
      var campos = [
        'fldx_empresa_',
        'fldx_puesto_',
        'fldx_fechainicio_',
        'fldx_tipoFin_',
        //'fldx_fechafin_',
        'fldx_sueldomensual_',
        'fldx_motivosepara_',
        'fldx_contacto_',
        'fldx_relacioncontacto_',
        'fldx_telcontacto_', 
        'fldx_tengocontacto_', 
        'fldx_comentario_'
      ]
      campos.forEach( item=>{ 
        var namex = item + indexItem
        if (this.$refs[namex][0]){
          this.$refs[namex][0].reset()
        }
      })
    },
    //---------
    fldx_checkDataContacto ( value, event, empleoItem, indexEmpleo ) {
      if (value===1 ){ // NO tiene contactos 
        empleoItem.nombre_contacto = ''
        empleoItem.relacion_laboral = ''
        empleoItem.telefono_contacto = ''
        empleoItem.telefono_contactoerrores = ''
      }
      this.$forceUpdate()
    },
    //--------------------------------
    async initFormData () {
      var test=11
      var starterData = [tplpikb_Data]
      var mcModel = this.getModelRequest('profile_Laboral')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }
      //profile_Laboral:['v2', 'profile/experiencia-laboral'] // ${secure}://${server}/index.php/api/v2/profile/experiencia-laboral?per-page=all&sort=id 
      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, {'per-page': 'all', sort:'id'}, true, null)

      await this.doRequestByPromise(this.$apiV2, 'pikb_Data', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.manifiesto_decir_verdad  = false
        this.frmDataOriginal = JSON.parse(JSON.stringify(starterData))
        this.frmData = JSON.parse(JSON.stringify(starterData))
        this.pikb_resetEmpleoFields(0)
        return false 
      }

      var empxData = reqRes.action[0].data.data
      this.empleoRecords = JSON.parse(JSON.stringify(empxData))

      if (Array.isArray(empxData) && empxData.length>=1){ 
        // Manipulamos la info para llenar los datos en los valores 
        var newData = []
        this.manifiesto_decir_verdad  = true 

        empxData.forEach( itemx => { 

          var usePhone = '' //+525555556666

          if (itemx.telefono_contacto === undefined || itemx.telefono_contacto === null || itemx.telefono_contacto === ''){
            usePhone = ''
          } else { 
            usePhone = ('' + itemx.telefono_contacto).replace('+52','')
          }
          var useSueldo = ''
          if ( itemx.sueldo_mensual !== undefined &&  itemx.sueldo_mensual !== null  && itemx.sueldo_mensual !== '' ) {
            useSueldo = '$ ' + this.mynumeral( parseFloat( itemx.sueldo_mensual ) ).format('0,0.00')
          }

          var newDataRec = {
            id: itemx.id ,
            nombre_empresa: '' +  itemx.nombre_empresa ,
            puesto: '' + itemx.puesto , // min y max = 			max 45
            fechaInicio:{ fecha:'' , year: null, month: null },
            actualmente_trabaja_ahi: itemx.actualmente_trabaja_ahi ,  // 0 = NO  1= SI 

              fechaFin:{ fecha:'' , year: null, month: null },
              catalogo_id: (itemx.actualmente_trabaja_ahi!==0) ? null : itemx.catalogo_id ,  //MOtivo separacion

            cuenta_con_datos_contacto: itemx.cuenta_con_datos_contacto ,   //  --- 0 = NO  1= SI 

              nombre_contacto: (itemx.nombre_contacto === undefined || itemx.nombre_contacto === null ) ? '' : '' +  itemx.nombre_contacto , 
              relacion_laboral: (itemx.relacion_laboral === undefined || itemx.relacion_laboral === null ) ? '' : '' +  itemx.relacion_laboral , 
              telefono_contacto: '' + usePhone, //min y max = max 13 

            sueldo_mensual: useSueldo ,
            comentarios: (itemx.comentarios === undefined || itemx.comentarios === null ) ? '' : '' +  itemx.comentarios ,  // campo TEXT 
          }

          if (itemx.fecha_inicio !== null){
            var val_fechaIni = itemx.fecha_inicio
            var fechasValsIni = []
            fechasValsIni = val_fechaIni.split('-') 
            newDataRec.fechaInicio = {
              fecha: val_fechaIni,
              year:  parseInt(fechasValsIni[0]) ,
              month: parseInt(fechasValsIni[1])
            }
          }

          if (itemx.fecha_fin  !== null){
            var val_FechaFin = itemx.fecha_fin
            var fechasValsFin = []
            fechasValsFin = val_FechaFin.split('-') 
            newDataRec.fechaFin = {
              fecha: val_FechaFin,
              year: parseInt(fechasValsFin[0]) ,
              month: parseInt(fechasValsFin[1])
            }
          }

          newData.push(newDataRec)

        })

        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        this.frmData = JSON.parse(JSON.stringify(newData))

      } else { // No hay info todavia 

        this.manifiesto_decir_verdad  = false
        this.frmDataOriginal = JSON.parse(JSON.stringify(starterData))
        this.frmData = JSON.parse(JSON.stringify(starterData))
        this.pikb_resetEmpleoFields(0)

      }
      return true
    },
    //--------------------------------
    async pikb_AddEmpleo(){ 
      if (this.frmData.length>=3){ 
        return false 
      }
      var newRec = JSON.parse(JSON.stringify(tplpikb_Data))
      this.frmData.push(newRec)
      var newIndex = (this.frmData.length - 1)
      await this._waitRequestProc(100)
      this.$forceUpdate()
      await this._waitRequestProc(100)
      this.pikb_resetEmpleoFields(newIndex)
      return true
    },
    async pikb_removeEmpleo(indexEmpleo){
      if (this.frmData.length<=1){
        return false 
      }
      this.frmData.splice(indexEmpleo, 1) 
      this.$forceUpdate()
      await this._waitRequestProc(100)
      return true
    },
    create_SaveRequestData: function ( info , index , modo) {
      // modo = 0 _: POST  2: PATCH 
      var myInfo = {}
      var dx = null 
      if (modo === 0){ // nuevo registro

        dx = JSON.parse(JSON.stringify(this.frmData[index]))
        myInfo.nombre_empresa = dx.nombre_empresa
        myInfo.puesto = dx.puesto
        myInfo.fecha_inicio = dx.fechaInicio.fecha 
        myInfo.sueldo_mensual = dx.sueldo_mensual.replace('$ ','').replace(/\,/g,'').trim()
        // fecha fin 
        myInfo.actualmente_trabaja_ahi = dx.actualmente_trabaja_ahi
        if (dx.actualmente_trabaja_ahi===0){
          myInfo.catalogo_id = dx.catalogo_id
          myInfo.fecha_fin = dx.fechaFin.fecha 
        }
        myInfo.cuenta_con_datos_contacto = dx.cuenta_con_datos_contacto 
        if (dx.cuenta_con_datos_contacto===0){ 
          if (dx.nombre_contacto.trim()!=''){
            myInfo.nombre_contacto  = dx.nombre_contacto.trim()
          }
          if (dx.relacion_laboral.trim()!=''){
            myInfo.relacion_laboral  = dx.relacion_laboral.trim()
          }
          if (dx.telefono_contacto.trim()!=''){
            myInfo.telefono_contacto = '+52' + dx.telefono_contacto
          }
        }
        if (dx.comentarios.trim()!==''){ 
          myInfo.comentarios = dx.comentarios
        }
        myInfo.manifiesto_decir_verdad = 1

      } else { 
        
        var campos = [
          'nombre_empresa', 'puesto','sueldo_mensual' , 'actualmente_trabaja_ahi','comentarios','cuenta_con_datos_contacto',
          'telefono_contacto','relacion_laboral','nombre_contacto'
        ]
        campos.forEach ( campox => { 
          if (info._[campox].status === 'MODIFIED'){ 
            if (campox==='sueldo_mensual'){ 
              myInfo[campox] =  ('' + info._[campox].current).replace('$ ','').replace(/\,/g,'').trim()
            } else if (campox === 'telefono_contacto' ){ 
              myInfo[campox] =  '+52' + info._[campox].current.trim()
            } else { 
              myInfo[campox] =  info._[campox].current
            }
          }
        })

        if (info._.actualmente_trabaja_ahi.status === 'MODIFIED' && info._.actualmente_trabaja_ahi.current === 0 ){ 
          myInfo.catalogo_id = info._.catalogo_id.current 
          myInfo.fecha_fin = info._.fechaFin._.fecha.current 
        } else {
          if (info._.fechaFin.status==='MODIFIED'){ 
            myInfo.fecha_fin = info._.fechaFin._.fecha.current 
          }
          if (info._.catalogo_id.status==='MODIFIED'){ 
            myInfo.catalogo_id = info._.catalogo_id.current 
          }
        }
        if (info._.fechaInicio.status==='MODIFIED'){ 
          myInfo.fecha_inicio = info._.fechaInicio._.fecha.current 
        }
        myInfo.manifiesto_decir_verdad = 1
      }
      return myInfo
    },

    doSubmitData: async function () {

      this.$cannaDebug('-- psiPage_empleos --   do Submit Data --- ')
      var test = 11 
      var toAdd = [], toRemove = [], toPatch = []
      var tmpArrTest = null
      var allowNextPage = false 
      var validForm = false 
      if (this.$refs.formstepPiK){
        await this.$refs.formstepPiK.validate().then(respx => { 
          validForm = respx
        })
      }
      if (validForm===false || this.mainErrors > 0){ 
        return false 
      }
      this.showLoadx(true, 1)

      var originalRecords = JSON.parse(JSON.stringify(this.empleoRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 
      var testA = this.myDifferify.compare(originalValues, newValues)

      if (testA.changes > 0 ) {

        // lo que se agrega 
        testA._.forEach( (item, indexItem) => { 
          var datacfgAdd = null 
          if (item.status === 'MODIFIED'){ 
            if (item._.id.current ===  null && item._.id.original === null ||  item._.id.current ===  null && item._.id.original !== null){ 
              datacfgAdd = this.create_SaveRequestData(null, indexItem, 0 )
              toAdd.push( [ JSON.parse(JSON.stringify(datacfgAdd)), indexItem ] )
            }
          } else if (item.status === 'ADDED'){ 
            datacfgAdd = this.create_SaveRequestData(null, indexItem, 0 )
            toAdd.push(  [  JSON.parse(JSON.stringify(datacfgAdd)), indexItem ] )
          }
        })
        // lo que se elimina
        if (originalRecords.length>0){
          originalRecords.forEach( item=>{ 
            var indexExist = this.frmData.findIndex( itemEmp => itemEmp.id === item.id )
            if (indexExist<0){ 
              toRemove.push(item.id)
            }
          })
        }
        // Lo que se edita  
        testA._.forEach( (item, indexItem) => { 
          var datacfgEdit = null 
          if (item.status === 'MODIFIED'){ 
            if (item._.id.current !==  null && item._.id.original === item._.id.current){ 
              datacfgEdit = this.create_SaveRequestData( item , indexItem, 1 )
              toPatch.push([ item._.id.current, JSON.parse(JSON.stringify(datacfgEdit))] )
            } else if (item._.id.current !==  null && item._.id.original !== item._.id.current){ 
              var indexExist = this.empleoRecords.findIndex( itemEmp => itemEmp.id === item._.id.current)
              if (indexExist>=0){ 
                datacfgEdit = this.create_SaveRequestData( item , indexItem , 1 )
                toPatch.push([ item._.id.current, JSON.parse(JSON.stringify(datacfgEdit))] )
              }
            }
          }
        })
        //Se inician los requests

        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], patch: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, patch: 0, addx: 0 }
        var mcModel = this.getModelRequest('profile_Laboral')

        // profile_Laboral:['v2', 'profile/experiencia-laboral']
        // ${secure}://${server}/index.php/api/v2/profile/experiencia-laboral/1?per-page=all&sort=id

        // if (test!=333) { 
        //   alert('Salida de escape')
        //   this.showLoadx(false, 1)
        //   return false 
        // }

        // ----------------------
        // Registros a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se hace path 
        if (toPatch.length > 0) {
          var toPatchReqs = []
          for (const pxRC of toPatch) {
            toPatchReqs.push(this.createAxiosConfig ('patch', mcModel, {delimiters:[pxRC[0]], useData:true,  data: pxRC[1] }, true, null))
          }
          for (const patchRQx of toPatchReqs) {
            if (reqErr.patch <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataPatch', reqRes.patch, patchRQx).then((resx) => {
                var tmpresponseData = reqRes.patch[reqRes.patch.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.patch += 1
                }
              })
            }
          }
          if (reqErr.patch > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.patch[0], reqErr.patch)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se agrega 
        if (toAdd.length > 0) {
          var toAddReqs = []
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC[0] === 'object' && Array.isArray(addRC[0]) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC[0]
              toAddReqs.push( [this.createAxiosConfig ('post', mcModel, { delimiters: [], useData:true,  data: addParams }, true, null) ,  addRC[1] ] ) // config, indice de item en frmData 
            }
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx[0] ).then((resx) => {
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                } else {
                  //se agrega el registro a originales con el id q dieron 
                  this.frmData[addRQx[1]].id = tmpresponseData.data.data.id 
                  var newElOriginal = JSON.parse(JSON.stringify(this.frmData[addRQx[1]]))
                  this.frmDataOriginal.push (newElOriginal)
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[reqRes.addx.length-1], reqErr.addx)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // Terminamos
        await this._waitRequestProc(300)
        allowNextPage = true

      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }

      // if (test != 22 ){ 
      //   this.showLoadx(false, 1)
      //   return false 
      // }

      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.LABORAL_EXPERIENCIA ){ // < 23 
        await this.hpb_updateProfileStatus( this.zstatUser.LABORAL_EXPERIENCIA ).then(respx=>{
          retUPS = respx
        })
      }

      //if (toRemove.length > 0 || toPatch.length > 0 || toAdd.length > 0){ // NOTA  DEv-sp 12  si se modificaron datos (nuevo o posterior ) - si tiene experiencia o no Recargar full profile (filtros user y ultimos empleos)
      await this.hpb_refreshProfileInfo ().then(respx=>{
        retUPS = respx
      })
      await this._waitRequestProc(300);
      //}

      // -----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/aptitudes-d' //Curriculum
        this.$router.push(newRuta)
      }
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_empleos --   beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_empleos --   created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_empleos --    beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_empleos --   mounted')
    this.zloadingData = false
    this.showLoadx(true, 2)

    var categorias = [80]
    this.pij_createYearDataList() // Crea la lista años 

    for (let categx of categorias) { 
      if (this.getHelperCat(categx).dataLoaded===false){ 
        var testCatA = false 
        await this.helpermix_getCatalog (categx, null).then( respx => { 
          this.$cannaDebug('-- psiPage_empleos -- categoria ' + categx  + ' finish ')
          testCatA = respx 
        })
      }
      await this._waitRequestProc(100)
    }

    await this._waitRequestProc(200)
    //----------- motivo de separacion 
    var allowedVals = []
    this.getHelperCat(80).dataCatalog.forEach( item => { allowedVals.push(item.id) })
    this.z_allowmotivoSepara = allowedVals.slice(0)
    allowedVals = []
    //-----------

    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$forceUpdate()

    await this._waitRequestProc(200)

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_empleos --   beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_empleos --   updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_empleos --   activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)

      await this._waitRequestProc(200)

      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      this.$forceUpdate()

      await this._waitRequestProc(100)

      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_empleos --   deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_empleos --   beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_empleos --   destroyed')
  }
}
</script>
