import { render, staticRenderFns } from "./pi_kb_laboral.vue?vue&type=template&id=63b258a1&"
import script from "./pi_kb_laboral.vue?vue&type=script&lang=js&"
export * from "./pi_kb_laboral.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QField from 'quasar/src/components/field/QField.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QInput,QIcon,QSelect,QItem,QItemSection,QItemLabel,QField,QOptionGroup,QCheckbox,QBtn});
